<template>
  <v-container
    id="collection"
    fluid
    tag="section"
    style="padding-top: 0px; padding-bottom: 0px"
  >
    <div
      v-if="collectionDataLoading && activeTab !== 'collection'"
      class="my-5"
    >
      <md-progress-spinner
        class="color_stroke"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <v-container v-else>
      <v-tabs v-model="activeTab" light grow show-arrows active-class="active">
        <v-tabs-slider class="tabs-slider"></v-tabs-slider>
        <v-tab href="#collection">
          <base-icon
            width="20"
            height="20"
            viewBox="0 0 29 29"
            :active="activeTab === 'collection'"
            v-slot="props"
          >
            <chat-icon :fill="props.slotColor" />
          </base-icon>
          &nbsp;Chat
        </v-tab>
        <v-tab href="#timeline">
          <base-icon
            width="20"
            height="20"
            viewBox="0 0 29 29"
            :active="activeTab === 'timeline'"
            v-slot="props"
          >
            <timeline-icon :fill="props.slotColor" />
          </base-icon>
          &nbsp;Timeline
        </v-tab>
        <v-tab href="#styles">
          <base-icon
            width="30"
            height="40"
            viewBox="0 -11 10 35"
            :active="activeTab === 'styles'"
            v-slot="props"
          >
            <style-list :fill="props.slotColor" />
          </base-icon>
          &nbsp;Styles
        </v-tab>
        <v-tab href="#about">
          <base-icon
            width="20"
            height="20"
            viewBox="0 0 29 29"
            :active="activeTab === 'about'"
            v-slot="props"
          >
            <project-board :fill="props.slotColor" />
          </base-icon>
          &nbsp;About
        </v-tab>
        <v-tab v-if="isBrandOrCreator" href="#options" @click="toggleOptions">
          <base-icon
            width="18"
            height="18"
            viewBox="0 0 26 26"
            :active="activeTab === 'options'"
            v-slot="props"
          >
            <options-icon
              :fill="props.slotColor"
              @edit="updateCollectionOnEdit"
            />
          </base-icon>
          &nbsp;Options
        </v-tab>
      </v-tabs>
      <v-tabs-items class="tabs-items" v-model="activeTab">
        <v-tab-item value="collection">
          <keep-alive>
            <chat-tab
              v-if="activeTab === 'collection'"
              :collectionId="$route.params.id"
              :refreshTimeline="timelineDataChangeFromPrevious"
              :assigned-suppliers="assignedSuppliers"
              :startingRoomId="Number($route.query.room_id)"
              :userId="userId"
              :isBrandOrCreator="isBrandOrCreator"
              @set-default="setDefaultImage"
              @stepper-click="clickTimelineStep"
            />
          </keep-alive>
        </v-tab-item>
        <v-tab-item value="timeline">
          <keep-alive>
            <timeline-tab
              v-if="activeTab === 'timeline'"
              :collectionId="$route.params.id"
              :clickedTimelineStep="clickedTimelineStep"
              :timelineDataChangeFromPrevious="timelineDataChangeFromPrevious"
              @stepper-click="clickTimelineStep"
            />
          </keep-alive>
        </v-tab-item>
        <v-tab-item value="styles">
          <keep-alive>
            <styles-tab
              v-if="activeTab === 'styles'"
              :collectionData="collectionData"
              :isBrandOrCreator="isBrandOrCreator"
            />
          </keep-alive>
        </v-tab-item>
        <v-tab-item v-if="isBrandOrCreator" value="options">
          <keep-alive>
            <options-tab
              v-if="activeTab === 'options'"
              :collectionData="collectionData"
              :isBrandOrCreator="isBrandOrCreator"
              @edit="updateCollectionOnEdit"
            />
          </keep-alive>
        </v-tab-item>
        <v-tab-item value="about">
          <keep-alive>
            <about-tab
              v-if="activeTab === 'about'"
              :collectionData="collectionData"
            />
          </keep-alive>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </v-container>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import restAdapter from "../../restAdapter";
import auth from "../../auth";
import notification from "../../notification";
import routes from "../../router/routes";
import store from "@/store";

const ChatTab = () => import("./tabItems/ChatTab.vue");
const TimelineTab = () => import("./tabItems/TimelineTab.vue");
const StylesTab = () => import("./tabItems/StylesTab.vue");
const AboutTab = () => import("./tabItems/AboutTab.vue");
const OptionsTab = () => import("./tabItems/OptionsTab.vue");

export default {
  name: "Collection",
  components: {
    ChatTab,
    TimelineTab,
    StylesTab,
    AboutTab,
    OptionsTab,
  },
  data: () => ({
    timelineDataChangeFromPrevious: false,
    activeTab: "styles",
    userId: null,
    collectionId: null,
    clickedTimelineStep: null,
    collectionName: "",
    selectedCurrency: "",
    editMode: true,
    collectionData: {},
    assignedSuppliers: [],
    collectionDataLoading: true,
    showButtons: true,
    productTypes: [],
  }),
  beforeRouteUpdate(to, from, next) {
    window.scrollTo(0, 0);
    next();
  },
  mounted() {
    this.collectionDataLoading = true;
    this.userId = auth.user.id;
    if (this.$route.query.room_id || this.$route.query.msg) {
      this.activeTab = "collection";
      this.getCollectionData();
    } else {
      this.getCollectionData(true);
    }
    this.getProductTypes();
  },
  computed: {
    isBrandOrCreator() {
      return (
        auth.user.account.organization_registry_id ===
          this.collectionData.brand_id ||
        auth.user.account.organization_registry_id ===
          this.collectionData.created_by_organization_id
      );
    },
  },
  methods: {
    clickTimelineStep(step) {
      this.clickedTimelineStep = step;
      this.activeTab = "timeline";
    },
    toggleOptions() {
      this.showButtons = true;
      this.editMode = false;
    },
    updateCollectionOnEdit(name) {
      console.log("updateCollectionOnEdit", name);
      this.collectionData.name = name;
    },
    getCollectionData(changeActiveTab = false) {
      this.collectionId = this.$route.params.id;
      restAdapter
        .get(`/api/collection/${this.collectionId}`)
        .then((response) => {
          this.collectionData = response.data.collection_data;
          const allSuppliers =
            response.data.collection_data.collection_suppliers?.map(
              (supplier) => ({
                id: supplier.supplier.id,
                name: supplier.supplier.name,
                avatar: supplier.supplier.account?.avatar,
                role:
                  supplier.supplier.type_details.short_code === "supplier"
                    ? "Supplier"
                    : "Brand",
              })
            ) || [];

          allSuppliers.push({
            id: response.data.collection_data.brand_organization.id,
            name: response.data.collection_data.brand_organization.name,
            avatar:
              response.data.collection_data.brand_organization.account?.avatar,
            role:
              response.data.collection_data.brand_organization.type_details
                .short_code === "supplier"
                ? "Supplier"
                : "Brand",
          });

          allSuppliers.push({
            id: response.data.collection_data.created_by_organization.id,
            name: response.data.collection_data.created_by_organization.name,
            avatar:
              response.data.collection_data.created_by_organization.account
                ?.avatar,
            role:
              response.data.collection_data.created_by_organization.type_details
                .short_code === "supplier"
                ? "Supplier"
                : "Brand",
          });

          const uniqueKeys = {};
          allSuppliers.map((supplier) => {
            uniqueKeys[supplier.id] = supplier;
            return supplier;
          });

          this.assignedSuppliers = Object.values(uniqueKeys);
          this.collectionDataLoading = false;
          if (this.collectionData) {
            store.commit("SET_UPDATE_COLLECTION_IN_APP_BAR", {
              collectionNumber: this.collectionData.collection_number,
              collectionName: this.collectionData.name,
            });
          }

          if (changeActiveTab) {
            this.activeTab =
              this.collectionData.inquiries.length == 0
                ? "styles"
                : "collection";
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            notification.error("Collection not found");
            this.$router.push({ name: routes.COLLECTION_LIST });
          }
          if (error.response.status === 403 || error.response.status === 401) {
            notification.error(
              "You are not authorized to view this collection"
            );
            this.$router.push({ name: routes.COLLECTION_LIST });
          }
        });
    },
    async getProductTypes() {
      await restAdapter.get("/api/inquire_product_types").then((response) => {
        this.productTypes = response.data.productTypes;
        this.productTypes.unshift({ id: 0, name: "All" });
      });
    },
    setDefaultImage(id) {
      restAdapter
        .post(`/api/collection/${this.collectionId}/set_default_image/`, {
          // eslint-disable-next-line @typescript-eslint/camelcase
          default_image: id,
        })
        .then(() => {
          notification.success("Image set as default thumbnail successfully");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.active {
  color: $primaryDark !important;
  font-weight: 600 !important;
  font-size: small;
  text-decoration: none !important;
  background-color: #ffffff !important;
  margin-bottom: -10px;
  margin-top: -20px;
}
.v-tab:not(.v-tab--active) {
  color: $primaryDark !important;
  font-weight: 500 !important;
  font-size: small;
  opacity: 0.35 !important;
  text-decoration: none !important;
  margin-bottom: -10px;
  margin-top: -20px;
}
.v-tab:hover {
  background-color: #a89caa;
  cursor: pointer;
}
.chat_card_container {
  margin: 0px;
  margin-top: 0px !important;
  padding: 0px;
}
.v-card {
  margin-top: 25px;
}
.tabs {
  color: $light;
}
.tabs-items {
  background-color: $light;
  margin-bottom: -10px;
  margin-top: -10px;
}
.tabs-slider {
  display: none;
}
.color_stroke::v-deep .md-progress-spinner-circle {
  stroke: #492a67 !important;
}
.md-field.md-theme-default.md-focused label {
  color: #442d65 !important;
}
.md-field.md-theme-default:before {
  background-color: #473068 !important;
}
</style>
